import MainPagesScrollController from 'components/MainPagesScrollController';
import { PageViewTrackerProvider } from 'context/PageViewTracker';
import { AnalyticsProvider } from 'hooks/useAnalytics';
import { LinguiI18nContextProvider } from 'hooks/useLinguiI18n';
import { BranchProvider } from 'lib/branch/BranchProvider';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Pages from './Pages';

const IntlPages = lazy(() => import('./IntlPages'));

export const isDevelopmentRoute = () => {
  return window.location.hostname.indexOf('insighttimer.com') === -1;
};

const Routes: React.FC<{}> = () => {
  return (
    <BrowserRouter>
      <BranchProvider>
        <MainPagesScrollController />
        <AnalyticsProvider>
          <PageViewTrackerProvider>
            <LinguiI18nContextProvider>
              <Suspense fallback={<></>}>
                <Switch>
                  {/* for debugging and logging in as another user with custom token.
                <Route
                  path="/auth"
                  component={() => {
                    firebaseAuth.signInWithCustomToken('');
                    return null;
                  }}
                /> */}
                  <Route
                    exact
                    path={['/privacy-policy', '/privacypolicy_separate']}
                    render={() => {
                      return <Redirect to="/legal/privacy-policy" />;
                    }}
                  />
                  <Route
                    exact
                    path={[
                      '/terms-of-service',
                      '/terms',
                      '/privacypolicy',
                      '/privacy'
                    ]}
                    render={() => {
                      return <Redirect to="/legal/terms-of-service" />;
                    }}
                  />

                  <Route
                    path="/:lang(br|es|es-es|es-mx|de|de-de|fr|fr-fr|fr-ca|nl|it)"
                    component={IntlPages}
                  />

                  {isDevelopmentRoute() && (
                    <Route path="/:lang(ru)" component={IntlPages} />
                  )}

                  <Route path="/" component={Pages} />
                </Switch>
              </Suspense>
            </LinguiI18nContextProvider>
          </PageViewTrackerProvider>
        </AnalyticsProvider>
      </BranchProvider>
    </BrowserRouter>
  );
};

export default Routes;
