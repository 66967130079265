import Axios from 'axios';
import { FirebaseError } from 'firebase/app';
import {
  AuthCredential,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  reauthenticateWithCredential,
  sendPasswordResetEmail as sendPasswordResetEmailFirebase,
  signInAnonymously,
  signInWithCredential,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  updatePassword
} from 'firebase/auth';
import { getTimestampAndISOTime } from 'helpers/datetime';
import deleteCookie from 'helpers/deleteCookie';
import fireEvent from 'helpers/fireEvent';
import getCookie from 'helpers/getCookie';
import setCookie from 'helpers/setCookie';
import { isDevelopment, isStaging } from 'lib/environment';
import { DateTime } from 'services/courses';
import { getUserCreatedEventDetails } from './auth-custom-events';
import { createDocRef, setDocument } from './utils';

export const firebaseAuth = getAuth();

interface PrivateSettingDoc {
  name: string;
  first_name: string;
  last_name: string;
  email?: string;
  tos_date_accepted?: DateTime;
  privacy_policy_accepted_at?: DateTime;
  accepted_privacy_policy_version?: string;
  accepted_terms_of_service_version?: string;
}

interface SetUserPrivateSettingsParams {
  uid: string;
  name: string;
  firstName: string;
  lastName: string;
  email?: string;
  tosChecked: boolean; // Terms and condition checked
}

/**
 * When new user register, use this method to store user details in their private settings.
 * Note: Do not use for other purpose. Use it only in new user register flow.
 */
export const setUserPrivateSettings = async ({
  uid,
  name,
  firstName,
  lastName,
  email,
  tosChecked = false // Set this to true for auth providers like google signup, apple signup etc
}: SetUserPrivateSettingsParams) => {
  const doc: PrivateSettingDoc = {
    name,
    first_name: firstName,
    last_name: lastName,
    email
  };

  if (tosChecked) {
    const tosAndPrivacyDateTime = getTimestampAndISOTime();

    doc.accepted_privacy_policy_version = '2.0';
    doc.accepted_terms_of_service_version = '3.0';
    doc.tos_date_accepted = tosAndPrivacyDateTime;
    doc.privacy_policy_accepted_at = tosAndPrivacyDateTime;
  }

  const docRef = createDocRef(`/users/${uid}/private/settings`);

  return setDocument(docRef, doc, { merge: true }).catch(
    (error: FirebaseError) => {
      console.error(
        'userRepository: failed to write to firestore private settings',
        error
      );
    }
  );
};

interface SignupWithEmailAndPasswordParams {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  tosChecked: boolean; // Terms and condition checked
}

export const signupWithEmailAndPassword = async ({
  email,
  firstName,
  lastName,
  password,
  tosChecked // Terms and condition checked
}: SignupWithEmailAndPasswordParams) => {
  const fName = firstName.trim();
  const lName = lastName.trim();
  const name = `${fName} ${lName}`;

  if (name.length < 3) {
    throw new Error('The name must be minimum 3 characters.');
  }

  try {
    const userRegisteredRouteDetails = getUserCreatedEventDetails();

    return await createUserWithEmailAndPassword(
      firebaseAuth,
      email,
      password
    ).then(async ({ user }) => {
      if (user) {
        await setUserPrivateSettings({
          uid: user.uid,
          name,
          firstName,
          lastName,
          email,
          tosChecked
        }).then(() => {
          fireEvent('user-details-created', userRegisteredRouteDetails);
        });
      }
      return user;
    });
  } catch (e) {
    const error: any = e;
    switch (error?.code) {
      case 'auth/invalid-email':
        throw new Error('Email address is not valid');
      case 'auth/weak-password':
        throw new Error('The password must be 6 characters long or more.');
      case 'auth/email-already-in-use':
        throw new Error('Email address is taken');
      default:
        throw new Error('Unable to signup. Please try again');
    }
  }
};

export const signInAsGuestUser = async () => {
  return signInAnonymously(firebaseAuth);
};

export const loginWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  try {
    return await signInWithEmailAndPassword(firebaseAuth, email, password).then(
      response => {
        return response;
      }
    );
  } catch (e) {
    const error: any = e;
    switch (error.code) {
      case 'auth/wrong-password':
        throw new Error(
          'Unable to login. Please check your password and try again'
        );
      case 'auth/invalid-email':
        throw new Error(
          'Your email address format is incorrect. Please check it and try again'
        );
      case 'auth/user-not-found':
        throw new Error(
          'Your email address is not recognized. Please try again'
        );
      default:
        throw new Error('Unable to login. Please try again');
    }
  }
};

export async function signInFacebook() {
  const facebookProvider = new FacebookAuthProvider();
  facebookProvider.addScope('public_profile,email');
  facebookProvider.setCustomParameters({
    display: 'popup'
  });
  return signInWithPopup(firebaseAuth, facebookProvider)
    .then(async result => {
      if (result.user) {
        console.info(`signInWithFacebook:SUCCESS - ${result.user.uid}`);
        return result;
      }
      console.info('signInWithFacebook:!user');
      throw new Error('user is null');
    })
    .catch(error => {
      console.error(
        `signInWithFacebook:FAILED - ${error.code}, ${error.message}`
      );
      throw error.message;
    });
}

export async function signInApple() {
  const appleProvider = new OAuthProvider('apple.com');
  appleProvider.addScope('email');
  appleProvider.addScope('name');
  appleProvider.setCustomParameters({
    display: 'popup'
  });
  return signInWithPopup(firebaseAuth, appleProvider)
    .then(async result => {
      if (result.user) {
        console.info(`signInWithApple:SUCCESS - ${result.user.uid}`);
        return result;
      }
      console.info('signInWithApple:!user');
      throw new Error('user is null');
    })
    .catch(error => {
      console.error(`signInWithApple:FAILED - ${error.code}, ${error.message}`);

      if (error.code === 'auth/popup-closed-by-user') {
        const err = new Error('Popup closed by user');
        throw err.message;
      }

      throw error.message;
    });
}

export async function signInGoogle() {
  const googleProvider = new GoogleAuthProvider();
  return signInWithPopup(firebaseAuth, googleProvider)
    .then(result => {
      if (result.user) {
        console.info(`signInWithGoogle:SUCCESS - ${result.user.uid}`);
        return result;
      }
      console.info('signInWithGoogle:!user');
      throw new Error('user is null');
    })
    .catch(error => {
      console.error(
        `signInWithGoogle:FAILED - ${error.code}, ${error.message}`
      );

      if (error.code === 'auth/popup-closed-by-user') {
        const err = new Error('Popup closed by user');
        throw err.message;
      }

      throw error.message;
    });
}

export function createFacebookCredential(fbToken: any) {
  return FacebookAuthProvider.credential(fbToken);
}

export async function getIdToken() {
  if (!firebaseAuth.currentUser) {
    throw new Error('not logged in');
  } else {
    return firebaseAuth.currentUser.getIdToken();
  }
}

export function getAuthHeaders(token: string) {
  return { headers: { Authorization: `Bearer ${token}` } };
}

export interface SessionLoginParams {
  redirectUrl?: string;
}

/**
 * Used to perform session login. Invoke it before redirect user to other insight timer based url.
 * */
export async function sessionLogin(params?: SessionLoginParams) {
  return firebaseAuth.currentUser
    ?.getIdToken()
    .then(token => {
      const url = `${process.env.REACT_APP_SHARED_AUTH_API_HOST}/api/v1/session_login`;

      return Axios.post(
        url,
        {
          idToken: token
        },
        {
          withCredentials: true
        }
      );
    })
    .then(res => {
      if (res) {
        setCookie({ key: 'hasSessionCookie', value: 'true' });

        if (params?.redirectUrl) {
          window.location.replace(params.redirectUrl);
        }

        return res;
      }

      return res;
    });
}

/**
 * Used to remove session cookie from browser.
 * */
const removeLocalSessionCookies = () => {
  deleteCookie('__session'); // In old flow, We have __session cookie store in browser. clear it on logout.
  deleteCookie('hasSessionCookie'); // In new flow, We have hasSessionCookie stored in browser. clear it on logout.
};

/**
 * Used to remove session cookie from server.
 * */
const removeRemoteSessionCookies = () => {
  const oldSessionCookie = getCookie('__session');

  if (oldSessionCookie) {
    return Axios({
      method: 'post',
      url: `https://insighttimer.com/auth/session_logout`,
      withCredentials: true
    });
  }

  return Axios.post(
    `${process.env.REACT_APP_SHARED_AUTH_API_HOST}/api/v1/session_logout`
  );
};

/**
 * Use to perform login via new session flow
 * */
const signInWithNewSessionCookie = () => {
  return Axios.post(
    `${process.env.REACT_APP_SHARED_AUTH_API_HOST}/api/v1/session_verify`,
    undefined,
    {
      withCredentials: true
    }
  )
    .then(resp => signInWithCustomToken(firebaseAuth, resp?.data))
    .then(result => {
      if (result?.user) {
        return result;
      }

      throw new Error('user is null');
    });
};

/**
 * SSO if already logged in from other insighttimer platform.
 */
export async function signInWithSessionCookie() {
  const sessionCookie = getCookie('hasSessionCookie');

  if (sessionCookie || isDevelopment || isStaging) {
    return signInWithNewSessionCookie();
  }

  throw new Error('SESSION_COOKIE_NOT_AVAILABLE');
}

/**
 * Used to remove session cookie from browser and server.
 * */
export const removeSessionCookies = () => {
  removeLocalSessionCookies();

  return removeRemoteSessionCookies();
};

/**
 * Used to remove session and then perform sign out from the system.
 * */
export async function logout() {
  await removeSessionCookies();
  return firebaseAuth.signOut();
}

export async function sendPasswordResetEmail(emailAddress: string) {
  return sendPasswordResetEmailFirebase(firebaseAuth, emailAddress)
    .then(async function() {
      console.info(`sendPasswordResetEmail:SUCCEED - ${true}`);
    })
    .catch(function(error) {
      console.error(
        `sendPasswordResetEmail:FAILED - ${error.code}, ${error.message}`
      );
      throw error.message;
    });
}

export const changePassword = async (
  currentPassword: string,
  newPassword: string,
  confirmPassword: string
) => {
  const user = firebaseAuth.currentUser;

  if (!user) throw new Error('Not logged in');

  if (currentPassword === '') throw new Error('Current password required');

  if (newPassword === '') throw new Error('New password required');

  if (confirmPassword === '') throw new Error('Confirm password required');

  if (newPassword !== confirmPassword)
    throw new Error('New passwords do not match');

  if (currentPassword === newPassword)
    throw new Error('New Password should not same as current password');

  const { email } = user;

  try {
    const credential = EmailAuthProvider.credential(
      email || '',
      currentPassword
    );
    if (firebaseAuth.currentUser)
      await reauthenticateWithCredential(
        firebaseAuth.currentUser,
        credential
      ).catch(() => {
        throw new Error('Current password is incorrect');
      });
    if (firebaseAuth.currentUser)
      await updatePassword(firebaseAuth.currentUser, newPassword).catch(
        (err: any) => {
          throw new Error(err.message);
        }
      );
  } catch (e) {
    const error: any = e;
    throw new Error(error?.message);
  }
};

export async function signInWithCredentialFirebase(credential: AuthCredential) {
  return signInWithCredential(firebaseAuth, credential);
}

export async function signInWithCustomTokenFirebase(customToken: string) {
  return signInWithCustomToken(firebaseAuth, customToken);
}
