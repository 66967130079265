enum PageTypes {
  Signup = 'signup_page',
  Login = 'login_page',
  Logout = 'logout_page',
  ResetPassword = 'reset_password_page',
  Demo = 'ab_test_demo_page',
  Home = 'home_page',
  MemberPlus = 'memberplus_page',
  TermsPrivacy = 'terms_privacy_page',
  MediaEnquiries = 'media_enquiries_page',
  MeditationTimer = 'meditation_timer_page',

  Teams = 'teams_page',

  TeacherJoin = 'teacher_join_page',
  TeacherBrowse = 'teacher_browse_page',
  TeacherProfile = 'teacher_profile_page',

  GroupsBrowse = 'group_browse_page',
  CourseBrowse = 'course_browse_page',
  CourseDetails = 'course_details_page',

  MyCourses = 'my_courses_page',
  CoursePlayer = 'course_player_page',

  HashtagDetails = 'hashtag_details_page',

  PlaylistBrowse = 'playlist_browse_page',
  PlaylistHashtag = 'hashtag_details_page',
  PlaylistDetails = 'playlist_details_page',
  PlaylistPlayer = 'playlist_player_page',

  TopicAll = 'topic_all_page',
  TopicDetails = 'topic_details_page',
  TopicBrowse = 'topic_browse_page',

  GmDetails = 'gm_details_page',
  GmBrowse = 'gm_browse_page',
  GmPodCastBrowse = 'gm_podcast_browse_page',

  LocalBrowse = 'local_browse_page',
  LocalDetails = 'local_details_page',
  LocalTeachers = 'local_teachers_browse_page',

  PageNotFound = 'page_not_found',

  MeditationMusic = 'meditation_music_page',
  MeditationPractices = 'meditation_practices_page',
  MeditationOrigins = 'meditation_origins_page',
  MeditationBenefits = 'meditation_benefits_page',
  MeditationApp = 'meditation_app_page',
  MeditateAtWork = 'meditate_at_work_page',
  MeditationGroups = 'meditation_groups',
  About = 'about_page',

  LocalDirectory = 'local_directory_page',
  MeditationCoursesDirectory = 'meditation_courses_directory_page',
  PlaylistsDirectory = 'playlists_directory_page',
  MeditationTeachersDirectory = 'meditation_teachers_directory_page',
  GuidedMeditationDirectory = 'guided_meditation_directory_page',

  CirclesTeams = 'circles_teams_page',
  AtWork = 'at_work_page',
  Yoga = 'yoga_page',
  HelpNow = 'help_now_page',

  CircleGroupPage = 'circle_group_page',

  LiveEventPage = 'live_browse_page',
  FreeTrialPage = 'free_trial_page',

  ContentfulPage = 'contentful_page',
  WorkshopBrowsePage = 'workshops_browse_page',
  WorkshopDetailsPage = 'workshops_details_page',
  WorkshopTermsOfServicePage = 'workshops_terms_of_service_page',
  WorkshopPurchaseSuccessPage = 'workshops_purchase_success_page',

  Unknown = 'unknown_page'
}

export default PageTypes;
