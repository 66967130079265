import * as Branch from 'api/branch';
import isPrerenderCrawler from 'helpers/isPrerenderCrawler';
import pickBy from 'lodash/pickBy';
import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState
} from 'react';
import { useHistory } from 'react-router';
import PageTypes from './PageTypes';
import { initialState, reducer } from './PageViewTrackerContext';

const PageViewTrackerContext = React.createContext<
  | {
      pageType: PageTypes;
      deepLink?: string;
      trackPageView: (args: TrackPageViewArgs, isSendEvent?: boolean) => void;
    }
  | undefined
>(undefined);

export const PageViewTrackerProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const [currentPath, setCurrentPath] = useState<string>(
    window.location.pathname
  );
  const isLandingRef = useRef(true);

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    history.listen((_location, action) => {
      // Reset scroll to top only when new history added.
      if (action === 'PUSH') {
        window.scroll(0, 0);
      }
    });
  }, [history]);

  useEffect(() => {
    dispatch({ type: 'PAGE_CHANGED' });
  }, [history.location.pathname]);

  useEffect(() => {
    // Need to reset eventSent to false otherwise. Branch journey will show legacy data.
    dispatch({ type: 'EVENT_SENT', eventSent: false });
  }, [state.branchData, state.deepLink]);

  // handle page change events
  useEffect(() => {
    const { branchData, deepLink, eventSent } = state;

    if (eventSent) {
      return;
    }

    dispatch({ type: 'EVENT_SENT', eventSent: true });

    const journeyData = {
      ...(branchData ?? {})
    };

    if (deepLink) {
      journeyData.$deeplink = deepLink;
      journeyData.$deeplink_v2 = deepLink;
    }

    if (isPrerenderCrawler()) {
      return;
    }

    Branch.branchCloseJourneyPopup(() => {
      setTimeout(() => {
        Branch.setBranchViewData(journeyData);

        // Have to has_deeplink field as it is added in branch journey display criteria.
        Branch.trackPageView({ has_deeplink: deepLink ? 'true' : 'false' });
      });
    });

    if (isLandingRef.current) {
      isLandingRef.current = false;
    }
  }, [state, dispatch]);

  // handle page changes.
  useEffect(() => {
    return history.listen(location => {
      if (location.pathname !== currentPath) {
        setCurrentPath(location.pathname);
      }
    });
  }, [history, currentPath]);

  const trackPageView = useCallback(
    (
      { pageType, deepLink, ...args }: TrackPageViewArgs,
      isSendEvent = true
    ) => {
      if (!isPrerenderCrawler()) {
        dispatch({
          pageType,
          type: 'TRACK_PAGE_VIEW',
          deepLink,
          branchData: pickBy(args, (_val, key) => key.startsWith('$')),
          isSendEvent
        });
      }
    },
    [dispatch]
  );

  return (
    <PageViewTrackerContext.Provider
      value={{
        pageType: state.pageType,
        trackPageView,
        deepLink: state.deepLink
      }}
    >
      {children}
    </PageViewTrackerContext.Provider>
  );
};

export const usePageViewTracker = () => {
  const context = useContext(PageViewTrackerContext);
  if (context === undefined) {
    throw new Error('Missing Provider!');
  }
  return context;
};

export type TrackPageViewArgs =
  | {
      pageType: PageTypes;
      slug?: string;
      page_language?: string;
      deepLink?: string;
      $og_title?: string;
      $og_description?: string;
      $og_image_url?: string;
    }
  | {
      pageType: PageTypes.ContentfulPage;
      deepLink?: string;
      slug: string;
      page_language: string;
    }
  | {
      pageType: PageTypes.CircleGroupPage;
      deepLink: string;
      page_language: string;
      slug?: string;
      group_id: string;
      group_name: string;
      group_type: string;
      group_host_user_id: string;
      email_domain: string;
      $og_title?: string;
      $og_description?: string;
      $og_image_url?: string;
    }
  | {
      pageType: PageTypes.CourseDetails;
      deepLink: string;
      slug: string;
      publisher_id: string;
      publisher_name: string;
      course_id: string;
      course_name: string;
      page_language: string;
      $og_title: string;
      $og_description: string;
      $og_image_url: string;
      $og_audio: string;
      $og_audio_type: 'audio/mpeg';
      $content_type: 'course';
      $rating_score: number;
      $duration_days: number;
      $publisher_name: string;
    }
  | {
      pageType: PageTypes.GmDetails;
      deepLink: string;
      page_language: string;
      slug: string;
      publisher_id: string;
      publisher_name: string;
      media_item_id: string;
      media_item_name: string;
      $og_title: string;
      $og_description: string;
      $og_image_url: string;
      $og_audio: string;
      $og_audio_type: 'audio/mpeg';
      $content_type: string;
      $rating_score: number;
      $duration_seconds: number;
      $play_count: number;
      $publisher_name: string;
    }
  | {
      pageType: PageTypes.TeacherProfile;
      deepLink: string;
      page_language: string;
      slug: string;
      publisher_id: string;
      publisher_name: string;
      $og_title?: string;
      $og_description?: string;
      $og_image_url?: string;
    }
  | {
      pageType: PageTypes.TopicDetails;
      deepLink: string;
      slug?: string;
      interest_id: string;
      $og_title?: string;
      $og_description?: string;
      $og_image_url?: string;
    }
  | {
      pageType: PageTypes.TopicDetails;
      deepLink?: string;
      slug?: string;
    }
  | {
      pageType: PageTypes.Login | PageTypes.Logout | PageTypes.Signup;
      deepLink?: string;
    }
  | {
      pageType: PageTypes.PlaylistPlayer | PageTypes.PlaylistDetails;
      slug?: string;
      deepLink?: string;
      publisher_id?: string;
      publisher_name?: string;
      playlist_id: string;
      playlist_name: string;
    };
