import MemberPlusHeroBR from 'assets_2/images/membersplus/v2/hero/br.jpg';
import MemberPlusHeroDE from 'assets_2/images/membersplus/v2/hero/de.jpg';
import MemberPlusHeroEN from 'assets_2/images/membersplus/v2/hero/en.jpg';
import MemberPlusHeroES from 'assets_2/images/membersplus/v2/hero/es.jpg';
import MemberPlusHeroFR from 'assets_2/images/membersplus/v2/hero/fr.jpg';
import MemberPlusHeroNL from 'assets_2/images/membersplus/v2/hero/nl.jpg';
import { BaseLocales, Locales, RegionalLocales } from './i18nLingui';
import { localConfigs } from './LocalConfigs';

interface Language {
  name: string;
  locale: Locales;
}

export const defaultLanguageSelector = {
  name: 'USA',
  locale: BaseLocales.English
};

export const LANGUAGES_LIST: { [key: string]: string } = {
  en: 'English',
  'pt-BR': 'BR. Português',
  de: 'Deutsch',
  it: 'Italiano',
  fr: 'Français',
  zh: '普通話',
  es: 'Español',
  sv: 'Svenska',
  pt: 'Português',
  id: 'Indonesian',
  da: 'Dansk',
  no: 'Norsk',
  fi: 'Suomi',
  nl: 'Nederlands',
  ja: '日本語',
  ms: 'Malay',
  ru: 'Русский',
  'de-CH': 'Schweizerdeutsch',
  'fr-CH': 'Français de Suisse',
  gl: 'Galego',
  lt: 'Lietuvių kalba',
  is: 'Icelandic',
  uk: 'український',
  bg: 'български',
  cs: 'Čeština',
  et: 'Eesti',
  vi: 'Tiếng Việt',
  zht: '臺灣閩南語',
  tr: 'Türk',
  ca: 'Català',
  kn: 'ಕನ್ನಡ',
  hu: 'Magyar',
  ko: '한국어',
  ro: 'Română',
  ar: 'العربية',
  he: 'עברית',
  th: 'ภาษาไทย',
  hi: 'हिंदी',
  el: 'Ελληνική',
  pl: 'Polski',
  sr: 'Сербский',
  fa: 'Fārsi',
  ct: '廣東話',
  ur: 'اُردُو',
  gu: 'ਗੁਰਮੁਖੀ',
  mn: 'Монгол хэл',
  ka: 'Georgian',
  m1: 'Music',
  ne: 'Nepali',
  pa: 'Pali',
  pi: 'Pali',
  sa: 'Sanskrit',
  sk: 'Slovakian',
  'zh-HK': 'Taiwanese Hokkien',
  'zh-TW': 'Cantonese'
};

export const languagesSelectorList: Language[] = [
  {
    name: 'Australia',
    locale: RegionalLocales.EnglishAustralia
  },
  {
    name: 'Brasil',
    locale: BaseLocales.BrazilianPortuguese
  },
  {
    name: 'Canada',
    locale: RegionalLocales.EnglishCanada
  },
  {
    name: 'Canada (Français)',
    locale: RegionalLocales.FrenchCanada
  },
  {
    name: 'Deutschland',
    locale: RegionalLocales.GermanGermany
  },
  {
    name: 'España',
    locale: RegionalLocales.SpanishSpain
  },
  {
    name: 'France',
    locale: RegionalLocales.FrenchFrance
  },
  {
    name: 'India',
    locale: RegionalLocales.EnglishIndia
  },
  {
    name: 'Italia',
    locale: BaseLocales.Italian
  },
  {
    name: 'México',
    locale: RegionalLocales.SpanishMexico
  },
  {
    name: 'Nederland',
    locale: BaseLocales.Dutch
  },
  {
    name: 'Österreich',
    locale: BaseLocales.German
  },
  {
    name: 'Schweiz',
    locale: BaseLocales.German
  },
  {
    name: 'Suisse',
    locale: BaseLocales.French
  },
  {
    name: 'United Kingdom',
    locale: RegionalLocales.EnglishUK
  },
  defaultLanguageSelector
];

export interface LocalPathInterface {
  basePath?: string;
  contentPath?: string;
  getRelativeUrl: () => string;
}

export class LocalPath implements LocalPathInterface {
  basePath?: string;

  contentPath?: string;

  constructor(basePath?: string, contentPath?: string) {
    this.basePath = basePath;
    this.contentPath = contentPath;
  }

  getRelativeUrl() {
    if (this.basePath) return `/${this.basePath}/${this.contentPath}`;
    return `/${this.contentPath}`;
  }
}

export const getSupportedLocale = (iso?: string) => {
  if (!iso) {
    return BaseLocales.English;
  }

  if (iso.startsWith('de')) {
    return BaseLocales.German;
  }
  if (iso.startsWith('es')) {
    return BaseLocales.Spanish;
  }
  if (iso.startsWith('it')) {
    return BaseLocales.Italian;
  }
  if (iso.startsWith('nl')) {
    return BaseLocales.Dutch;
  }
  if (iso.startsWith('fr')) {
    return BaseLocales.French;
  }
  if (iso === 'pt-BR') {
    return BaseLocales.BrazilianPortuguese;
  }
  return BaseLocales.English;
};

const teacherUrl = (locale: Locales, username?: string) => {
  switch (locale) {
    case BaseLocales.German:
    case RegionalLocales.GermanGermany:
      return `${
        localConfigs[BaseLocales.German].meditationTeachers
      }/${username}`;
    case BaseLocales.Spanish:
    case RegionalLocales.SpanishSpain:
    case RegionalLocales.SpanishMexico:
      return `${
        localConfigs[BaseLocales.Spanish].meditationTeachers
      }/${username}`;
    case BaseLocales.BrazilianPortuguese:
    case BaseLocales.Russian:
      return `${localConfigs[locale].meditationTeachers}/${username}`;
    case BaseLocales.French:
    case RegionalLocales.FrenchFrance:
    case RegionalLocales.FrenchCanada:
      return `${
        localConfigs[BaseLocales.French].meditationTeachers
      }/${username}`;
    case BaseLocales.Dutch:
      return `${
        localConfigs[BaseLocales.Dutch].meditationTeachers
      }/${username}`;
    case BaseLocales.Italian:
      return `${
        localConfigs[BaseLocales.Italian].meditationTeachers
      }/${username}`;
    default:
      return `${username}`;
  }
};

const singlesUrl = (locale: Locales, slug: string, username?: string) => {
  switch (locale) {
    case BaseLocales.German:
    case RegionalLocales.GermanGermany:
      return `${username}/${
        localConfigs[BaseLocales.German].guidedMeditations
      }/${slug}`;
    case BaseLocales.Spanish:
    case RegionalLocales.SpanishSpain:
    case RegionalLocales.SpanishMexico:
      return `${username}/${
        localConfigs[BaseLocales.Spanish].guidedMeditations
      }/${slug}`;
    case BaseLocales.BrazilianPortuguese:
    case BaseLocales.Russian:
      return `${username}/${localConfigs[locale].guidedMeditations}/${slug}`;
    case BaseLocales.French:
    case RegionalLocales.FrenchFrance:
    case RegionalLocales.FrenchCanada:
      return `${username}/${
        localConfigs[BaseLocales.French].guidedMeditations
      }/${slug}`;
    case BaseLocales.Dutch:
      return `${username}/${
        localConfigs[BaseLocales.Dutch].guidedMeditations
      }/${slug}`;
    case BaseLocales.Italian:
      return `${username}/${
        localConfigs[BaseLocales.Italian].guidedMeditations
      }/${slug}`;
    default:
      return `${username}/guided-meditations/${slug}`;
  }
};

export const courseUrl = (locale: Locales, slug: string) => {
  switch (locale) {
    case BaseLocales.German:
    case RegionalLocales.GermanGermany:
      return `${localConfigs[BaseLocales.German].meditationCourses}/${slug}`;
    case BaseLocales.Spanish:
    case RegionalLocales.SpanishSpain:
    case RegionalLocales.SpanishMexico:
      return `${localConfigs[BaseLocales.Spanish].meditationCourses}/${slug}`;
    case BaseLocales.BrazilianPortuguese:
    case BaseLocales.Russian:
      return `${localConfigs[locale].meditationCourses}/${slug}`;
    case BaseLocales.French:
    case RegionalLocales.FrenchFrance:
    case RegionalLocales.FrenchCanada:
      return `${localConfigs[BaseLocales.French].meditationCourses}/${slug}`;
    case BaseLocales.Dutch:
      return `${localConfigs[BaseLocales.Dutch].meditationCourses}/${slug}`;
    case BaseLocales.Italian:
      return `${localConfigs[BaseLocales.Italian].meditationCourses}/${slug}`;
    default:
      return `${localConfigs[BaseLocales.English].meditationCourses}/${slug}`;
  }
};

const topicUrl = (locale: Locales, slug: string) => {
  switch (locale) {
    case BaseLocales.German:
    case RegionalLocales.GermanGermany:
      return `${localConfigs[BaseLocales.German].meditationTopics}/${slug}`;
    case BaseLocales.Spanish:
    case RegionalLocales.SpanishSpain:
    case RegionalLocales.SpanishMexico:
      return `${localConfigs[BaseLocales.Spanish].meditationTopics}/${slug}`;
    case BaseLocales.BrazilianPortuguese:
    case BaseLocales.Russian:
      return `${localConfigs[locale].meditationTopics}/${slug}`;
    case BaseLocales.French:
    case RegionalLocales.FrenchFrance:
    case RegionalLocales.FrenchCanada:
      return `${localConfigs[BaseLocales.French].meditationTopics}/${slug}`;
    case BaseLocales.Dutch:
      return `${localConfigs[BaseLocales.Dutch].meditationTopics}/${slug}`;
    case BaseLocales.Italian:
      return `${localConfigs[BaseLocales.Italian].meditationTopics}/${slug}`;
    default:
      return `${localConfigs[BaseLocales.English].meditationTopics}/${slug}`;
  }
};

const howToMeditateUrl = (locale: Locales) => {
  switch (locale) {
    case BaseLocales.German:
    case RegionalLocales.GermanGermany:
      return `${localConfigs[BaseLocales.German].meditationTopics}/${
        localConfigs[BaseLocales.German].howToMeditate
      }`;
    case BaseLocales.Spanish:
    case RegionalLocales.SpanishSpain:
    case RegionalLocales.SpanishMexico:
      return `${localConfigs[BaseLocales.Spanish].meditationTopics}/${
        localConfigs[BaseLocales.Spanish].howToMeditate
      }`;
    case BaseLocales.BrazilianPortuguese:
    case BaseLocales.Russian:
      return `${localConfigs[locale].meditationTopics}/${localConfigs[locale].howToMeditate}`;
    case BaseLocales.French:
    case RegionalLocales.FrenchFrance:
    case RegionalLocales.FrenchCanada:
      return `${localConfigs[BaseLocales.French].meditationTopics}/${
        localConfigs[BaseLocales.French].howToMeditate
      }`;
    case BaseLocales.Dutch:
      return `${localConfigs[BaseLocales.Dutch].meditationTopics}/${
        localConfigs[BaseLocales.Dutch].howToMeditate
      }`;
    case BaseLocales.Italian:
      return `${localConfigs[BaseLocales.Italian].meditationTopics}/${
        localConfigs[BaseLocales.Italian].howToMeditate
      }`;
    default:
      return `${localConfigs[BaseLocales.English].meditationTopics}/${
        localConfigs[BaseLocales.English].howToMeditate
      }`;
  }
};

const notifyLanguage = (locale: Locales) => {
  switch (locale) {
    case BaseLocales.German:
    case RegionalLocales.GermanGermany:
      return 'Deutsch besuchen';
    case BaseLocales.Spanish:
    case RegionalLocales.SpanishSpain:
    case RegionalLocales.SpanishMexico:
      return 'Español!';
    case BaseLocales.BrazilianPortuguese:
      return 'Português do Brasil';
    case BaseLocales.French:
    case RegionalLocales.FrenchFrance:
    case RegionalLocales.FrenchCanada:
      return 'Français.';
    case BaseLocales.Dutch:
      return 'Nederlands bezoeken';
    case BaseLocales.Italian:
      return 'Italiano';
    default:
      return null;
  }
};

const notifyString = (locale: Locales) => {
  switch (locale) {
    case BaseLocales.German:
    case RegionalLocales.GermanGermany:
      return 'Du kannst unsere Webseite auch auf';
    case BaseLocales.Spanish:
    case RegionalLocales.SpanishSpain:
    case RegionalLocales.SpanishMexico:
      return '¡Puedes visitar nuestra página en';
    case BaseLocales.BrazilianPortuguese:
      return 'Por favor, visite esta página em';
    case BaseLocales.French:
    case RegionalLocales.FrenchFrance:
    case RegionalLocales.FrenchCanada:
      return 'Vous pouvez aussi visiter notre site web en';
    case BaseLocales.Dutch:
      return 'U kunt onze website ook in het';
    case BaseLocales.Italian:
      return 'Puoi anche visitare il sito in';
    default:
      return null;
  }
};

/**
 * returns the home page url for this locale
 * @param locale
 */
const homeUrl = (locale: Locales) => {
  if (sessionStorage.getItem('language')) {
    return `/${sessionStorage.getItem('language')}`;
  }

  switch (locale) {
    case BaseLocales.BrazilianPortuguese:
      return '/br';
    case BaseLocales.PseudoLocale:
    case BaseLocales.English:
      return '/';
    default:
      return `/${locale.toString()}`;
  }
};

/**
 * returns the blog page url for this locale
 * @param locale
 */
const blogUrl = (locale: BaseLocales) => {
  switch (locale) {
    case BaseLocales.BrazilianPortuguese:
      return `${process.env.REACT_APP_WEBAPP_HOST}/blog/br`;
    case BaseLocales.German:
      return `${process.env.REACT_APP_WEBAPP_HOST}/blog/de`;
    case BaseLocales.Spanish:
      return `${process.env.REACT_APP_WEBAPP_HOST}/blog/es`;
    default:
      return `${process.env.REACT_APP_WEBAPP_HOST}/blog`;
  }
};

/**
 * returns the base locale for this locale (eg. de-de, returns de)
 * @param locale
 */
export const getBaseLocale = (locale: Locales): BaseLocales => {
  switch (locale) {
    case RegionalLocales.GermanGermany:
      return BaseLocales.German;
    case RegionalLocales.SpanishSpain:
    case RegionalLocales.SpanishMexico:
      return BaseLocales.Spanish;
    case RegionalLocales.FrenchFrance:
    case RegionalLocales.FrenchCanada:
      return BaseLocales.French;
    case RegionalLocales.EnglishAustralia:
    case RegionalLocales.EnglishCanada:
    case RegionalLocales.EnglishUK:
    case RegionalLocales.EnglishIndia:
      return BaseLocales.English;
    case RegionalLocales.Brazilian:
      return BaseLocales.BrazilianPortuguese;
    default:
      return locale;
  }
};

/**
 * browse guided-meditations path
 * @param locale
 */
const browsePath = (locale: Locales) => {
  return localConfigs[getBaseLocale(locale)].guidedMeditations;
};

export const getSessionLanguage = () => {
  const sessionLanguage = sessionStorage.getItem('language');

  if (!sessionLanguage) {
    return defaultLanguageSelector;
  }

  if (sessionLanguage === 'br') {
    return {
      name: 'Brasil',
      locale: BaseLocales.BrazilianPortuguese
    };
  }

  return languagesSelectorList.find(
    language => language.locale === sessionLanguage
  );
};

export const setSessionLanguage = (locale: Locales) => {
  if (locale === BaseLocales.English) {
    sessionStorage.setItem('language', '');
  } else if (locale === BaseLocales.BrazilianPortuguese) {
    sessionStorage.setItem('language', 'br');
  } else {
    sessionStorage.setItem('language', locale);
  }
};

const memberPlusHero = (locale: BaseLocales) => {
  switch (locale) {
    case BaseLocales.German:
      return MemberPlusHeroDE;
    case BaseLocales.Spanish:
      return MemberPlusHeroES;
    case BaseLocales.BrazilianPortuguese:
      return MemberPlusHeroBR;
    case BaseLocales.French:
      return MemberPlusHeroFR;
    case BaseLocales.Dutch:
      return MemberPlusHeroNL;
    default:
      return MemberPlusHeroEN;
  }
};

export const taxonomy = (locale: Locales) => {
  const localConfig = localConfigs[getBaseLocale(locale)];
  return {
    config: localConfig,
    getTeacherUrl: (username?: string) =>
      new LocalPath(
        localConfig.home,
        teacherUrl(locale, username)
      ).getRelativeUrl(),
    getSinglesUrl: (slug: string, username?: string) =>
      new LocalPath(
        localConfig.home,
        singlesUrl(getBaseLocale(locale), slug, username)
      ).getRelativeUrl(),
    getBrowseUrl: () =>
      new LocalPath(localConfig.home, browsePath(locale)).getRelativeUrl(),
    getCourseUrl: (slug: string) =>
      new LocalPath(localConfig.home, courseUrl(locale, slug)).getRelativeUrl(),
    getTopicUrl: (slug: string) =>
      new LocalPath(localConfig.home, topicUrl(locale, slug)).getRelativeUrl(),
    getHowToMeditateUrl: () =>
      new LocalPath(
        localConfig.home,
        howToMeditateUrl(locale)
      ).getRelativeUrl(),
    getHomeUrl: () => homeUrl(locale),
    getBlogUrl: () => blogUrl(getBaseLocale(locale)),
    getResetPasswordUrl: () =>
      new LocalPath(
        localConfig.home,
        localConfig.resetPassword
      ).getRelativeUrl(),
    getFreeTrialUrl: () =>
      new LocalPath(localConfig.home, localConfig.freeTrial).getRelativeUrl(),
    getNotificationString: () => notifyString(locale),
    getNotificationLanguage: () => notifyLanguage(locale),
    getMemberPlusHero: () => memberPlusHero(getBaseLocale(locale))
  };
};

export type TaxonomyReturnType = ReturnType<typeof taxonomy>;

const regionalLocalesList = Object.values(RegionalLocales);
export const baseLocalesList = Object.values(BaseLocales).filter(
  value => value !== BaseLocales.PseudoLocale
);
const localesList: string[] = [...regionalLocalesList, ...baseLocalesList];

export const isValidLocale = (lang?: string) => {
  if (!lang) {
    return false;
  }

  return localesList.includes(lang);
};

export const getValidBaseLocale = (lang?: string) => {
  const validLocale = isValidLocale(lang);

  if (!validLocale) {
    return null;
  }

  return getBaseLocale(lang as Locales);
};
