import { Flex, Spinner } from '@chakra-ui/react';
import { AuthState, useFirebaseAuth } from 'hooks/useFirebaseAuth';
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';

interface AuthRouteProps extends RouteProps {
  redirectTo?: string;
}

const AuthRoute = ({ redirectTo = '/', ...routeProps }: AuthRouteProps) => {
  const { authState } = useFirebaseAuth();

  if (authState === AuthState.PENDING) {
    return (
      <Flex height="200px" justifyContent="center" alignItems="center">
        <Spinner />
      </Flex>
    );
  }

  if (authState === AuthState.UNAUTHORIZED) {
    return <Redirect to={redirectTo} />;
  }

  return <Route {...routeProps} />;
};

export default AuthRoute;
