//  google recommended events:
// https://support.google.com/firebase/answer/9267735?hl=en&ref_topic=6317484
enum GA_EVENTS {
  CLICK = 'click',
  PAGE_VIEW = 'page_view',
  LOGIN = 'login',
  SIGN_UP = 'sign_up',
  PURCHASE = 'purchase',
  VIEW = 'view',
  EXPERIMENT_ACTIVATED = 'experiment_activated',
  SERVICE_EVENT = 'service_event'
}

export enum GA_CLICK_EVENT_NAMES {
  CHECKOUT_CLICKED = 'checkout_button_clicked',
  BROWSE_COURSE_CLICKED = 'browse_course_clicked',
  CHALLENGE_BANNER_CLICKED = 'challenge_banner_clicked',
  CONTACT_US_CLICKED = 'contact_us_clicked',

  DOWNLOAD_APP_IOS_CLICKED = 'download_app_ios_clicked',
  DOWNLOAD_APP_ANDROID_CLICKED = 'download_app_android_clicked',
  DOWNLOAD_APP_LISTEN_OFFLINE_CLICKED = 'download_app_listen_offline_clicked',
  DOWNLOAD_APP_PLAY_IN_APP_CLICKED = 'download_app_play_in_app_clicked',
  SHARE_BUTTON_CLICKED = 'share_button_clicked',
  PLAY_CLICKED = 'play_clicked',

  // Auth
  CONTINUE_WITH_GOOGLE_CLICKED = 'continue_with_google_clicked',
  CONTINUE_WITH_APPLE_CLICKED = 'continue_with_apple_clicked',
  CONTINUE_WITH_FACEBOOK_CLICKED = 'continue_with_facebook_clicked',
  SIGN_UP_WITH_EMAIL_CLICKED = 'sign_up_with_email_clicked',
  SIGN_UP_DISMISSED = 'sign_up_dismissed',
  LOG_IN_CLICKED = 'log_in_clicked',
  SIGN_UP_CLICKED = 'sign_up_clicked',

  // Course
  PLAY_LESSON_CLICKED = 'play_lesson_clicked',

  // Download App
  DOWNLOAD_APP_CLICKED = 'download_app_clicked', // download app button clicked on desktop
  DOWNLOAD_FREE_APP_CLICKED = 'download_free_app_clicked',

  // For companies:
  START_NOW_ITS_FREE_CLICKED = 'start_now_its_free_clicked',
  VIEW_PLANS_CLICKED = 'view_plans_clicked',
  GET_STARTED_FOR_FREE_CLICKED = 'get_started_for_free_clicked',

  // Redeem code
  REDEEM_CODE_CLICKED = 'redeem_code_clicked',

  // Onboarding Quiz
  ONBOARDING_OPTION_CLICKED = 'onboarding_option_clicked',
  ONBOARDING_DOWNLOAD_APP_CLICKED = 'onboarding_download_app_clicked',
  ONBOARDING_BACK_CLICKED = 'onboarding_back_clicked',
  ONBOARDING_CLOSE_MODAL_CLICKED = 'onboarding_close_modal_clicked'
}

export enum ANALYTICS_SERVICE_EVENT_NAMES {
  SHARE_COMPLETED = 'share_completed',
  BRANCH_APP_STORE_REDIRECT = 'branch_app_store_redirect',
  USER_REGISTRATION_COMPLETED = 'user_registration_completed'
}

export default GA_EVENTS;
